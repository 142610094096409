export default {
	messages: {
		ru: {
			'Экспертизу проводил': 'Экспертизу проводил',
			'ФИО эксперта': 'ФИО эксперта',
			'Подтверждаю достоверность данных': 'Подтверждаю достоверность данных в этом отчете (ФИО)',
			'Сохранить': 'Сохранить',
			'Спасибо! Oтчет успешно заполнен и отправлен.': 'Спасибо! Oтчет успешно заполнен и отправлен.',
			'Произошла ошибка сохранения отчета!': 'Произошла ошибка сохранения отчета! Обратитесь к менеджеру по работе с партнерами  услуги "Перевірені авто" на  AUTO.RIA, тел: (067) 430-66-19',
			'Для сохранения отчёта Вам необходимо заполнить ФИО и подтвердить достоверность данных в этом отчете!': 'Для сохранения отчёта Вам необходимо заполнить ФИО и подтвердить достоверность данных в этом отчете!',
			'версия': 'версия'
		},
		uk: {
			'Экспертизу проводил': 'Eкспертизу проводив',
			'ФИО эксперта': 'ПІБ експерта',
			'Подтверждаю достоверность данных': 'Підтверджую достовірність даних в цьому звіті (ПІБ)',
			'Сохранить': 'Зберегти',
			'Спасибо! Oтчет успешно заполнен и отправлен.': 'Дякуємо! Звіт успішно заповнений і відправлений.',
			'Произошла ошибка сохранения отчета!': 'Сталася помилка збереження звіту! Зверніться до менеджера по роботі з партнерами послуги "Перевірені авто" на AUTO.RIA, тел: (067) 430-66-19',
			'Для сохранения отчёта Вам необходимо заполнить ФИО и подтвердить достоверность данных в этом отчете!': 'Для збереження звіту Вам необхідно заповнити ПІБ і підтвердити достовірність даних в цьому звіті!',
			'версия': 'версія'
		}
	}
};
