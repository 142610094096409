export default function getBase64fromURL(url, outputFormat) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      let canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d'),
          dataURL;
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      //callback(dataURL);
      canvas = null;
      resolve(dataURL);
    };
    img.src = url;
  });
}