//todo проблема где-то здесь

export default function rotateImageBase64(base64, angle) {
  return new Promise(async (resolve, reject) => {
    let radian = angle * (Math.PI / 180);

    const img = new Image();
    img.src = base64;
    img.onload = await function () {
      const canvas = document.createElement('canvas');
      const width = img.width;
      const height = img.height;

      const cosa = Math.cos(radian);
      const sina = Math.sin(radian);
      const w = Math.abs(width * cosa) + Math.abs(height * sina);
      const h = Math.abs(width * sina) + Math.abs(height * cosa);
      const dx = (cosa < 0 ? Math.abs(width * cosa) : 0) + (sina > 0 ? Math.abs(height * sina) : 0);
      const dy = (sina < 0 ? Math.abs(width * sina) : 0) + (cosa < 0 ? Math.abs(height * cosa) : 0);

      canvas.height = h;
      canvas.width = w;

      const ctx = canvas.getContext("2d");
      ctx.transform(1, 0, 0, 1, dx, dy);
      ctx.rotate(radian);
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };

  });
}

