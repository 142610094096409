import {mapGetters} from 'vuex';

export default {
	name: 'IsShowBar',
	i18n: require('./i18n').default,
	data: function () {
		return {
			hideIt: false,
			selected: {}
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'LawyerCheck/autotestData'
		})
	},
	methods: {
		showAll() {
			this.hideIt = true;
			this.$http.post(
				'/autotest/saveAsShow/',
				{
					orderId: this.autotestData.orderData.orderId,
					status: 1
				}
			);
		}
	}
};
