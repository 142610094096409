export default {
	messages: {
		ru: {
			'Подсказка': 'Подсказка',
			'Позволяет перейти на любой тематический раздел или подраздел сайта без использования поиска': 'Позволяет перейти на любой тематический раздел или подраздел сайта без использования поиска',
			'AUTO.RIA — лидер автомобильной интернет-торговли': 'AUTO.RIA — лидер автомобильной интернет-торговли',
			'Результаты юридической проверки': 'Результаты юридической проверки',
			'Протокол диагностики, который Вы запрашиваете, не является «юридическим».': 'Протокол диагностики, который Вы запрашиваете, не является «юридическим».',
			'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,': 'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,',
			'который оставлял заявку': 'который оставлял заявку',
			'Также Вы можете воспользоваться другими услугами программы': 'Также Вы можете воспользоваться другими услугами программы',
			'К сожалению, доступ к протоколу диагностики не доступен для Вас.': 'К сожалению, доступ к протоколу диагностики не доступен для Вас.'
		},
		uk: {
			'Подсказка': 'Підказка',
			'Позволяет перейти на любой тематический раздел или подраздел сайта без использования поиска': 'Дозволяє перейти на будь-який тематичний розділ або підрозділ сайту без використання пошуку',
			'AUTO.RIA — лидер автомобильной интернет-торговли': 'AUTO.RIA — лідер автомобільної інтернет-торгівлі',
			'Результаты юридической проверки': 'Результати юридичної перевірки',
			'Протокол диагностики, который Вы запрашиваете, не является «юридическим».': 'Протокол діагностики, який Ви запитуєте, не є «юридичним».',
			'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,': 'Якщо Ви замовляли діагностику даного авто, авторизуйтесь як користувач,',
			'который оставлял заявку': 'що залишав заявку',
			'Также Вы можете воспользоваться другими услугами программы': 'Також Ви можете скористатись іншими послугами програми',
			'К сожалению, доступ к протоколу диагностики не доступен для Вас.': 'На жаль, доступ до протоколу діагностики не доступний для Вас.'
		}
	}
};
