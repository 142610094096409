import {mapGetters} from 'vuex';

export default {
	name: 'TitleBar',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {}
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'LawyerCheck/autotestData'
		})
	}
};
