export default {
	messages: {
		ru: {
			'№ заявки': '№ заявки',
			'Результаты юридической проверки': 'Результаты юридической проверки'
		},
		uk: {
			'№ заявки': '№ заявки',
			'Результаты юридической проверки': 'Результати юридичної перевірки'
		}
	}
};
