export default {
	messages: {
		ru: {
			'Фотоотчет': 'Фотоотчет'
		},
		uk: {
			'Фотоотчет': 'Фотозвіт'
		}
	}
};
