export default {
	messages: {
		ru: {
			'СЦ и контакты': 'СЦ и контакты',
			'Юридическая проверка': 'юридическая проверка',
			'Проверка достоверности': 'Проверка достоверности',
			'документов на авто': 'документов на авто',
			'Проверка номеров кузова и': 'Проверка номеров кузова и',
			'двигателя авто на подлинность': 'двигателя авто на подлинность'
		},
		uk: {
			'СЦ и контакты': 'СЦ і контакти',
			'Юридическая проверка': 'юридична перевірка',
			'Проверка достоверности': 'Перевірка достовірності',
			'документов на авто': 'документів на авто',
			'Проверка номеров кузова и': 'Перевірка номерів кузова і',
			'двигателя авто на подлинность': 'двигуна авто на відповідність'
		}
	}
};
