export default {
	messages: {
		ru: {
			'Информация о автомобиле': 'Информация о автомобиле',
			'договорная': 'договорная',
			'Пробег': 'Пробег',
			'Местоположение': 'Местоположение',
			'Тип топлива': 'Тип топлива',
			'Тип коробки передач': 'Тип коробки передач',
			'Диагностика авто на СТО с другого сайта': 'Диагностика авто на СТО с другого сайта',
			'Информация о владельце': 'Информация о владельце',
			'Проверенные телефоны': 'Проверенные телефоны',
			'Другие телефоны': 'Другие телефоны'
		},
		uk: {
			'Информация о автомобиле': 'Інформація про автомобіль',
			'договорная': 'договірна',
			'Пробег': 'Пробіг',
			'Местоположение': 'Місцезнаходження',
			'Тип топлива': 'Тип палива',
			'Тип коробки передач': 'Тип коробки передач',
			'Диагностика авто на СТО с другого сайта': 'Діагностика авто на СТО з іншого сайту',
			'Информация о владельце': 'Інформація про власника',
			'Проверенные телефоны': 'Перевірені телефони',
			'Другие телефоны': 'Інші телефони'
		}
	}
};
