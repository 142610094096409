import {mapGetters} from 'vuex';

export default {
	name: 'PdfVersionBar',
	data: function () {
		return {
			files: []
		};
	},
	computed: {
		...mapGetters({
			autotestData: 'LawyerCheck/autotestData',
			reportData: 'LawyerCheck/reportData'
		})
	},
	created() {
		this.files = this.reportData.mainObject.photoReport;
	}
};
