export default {
	messages: {
		ru: {
			'Ещё': 'Ещё',
			'фото': 'фото'
		},
		uk: {
			'Ещё': 'Ще',
			'фото': 'фото'
		}
	}
};
