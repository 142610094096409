import {mapGetters} from 'vuex';

export default {
	name: 'ApproveBar',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				fio: '',
				approve: false
			},
			hideIt: false,
			error: false,
			pdfUrl: '',
			pdfVisibleButton: false,
			saveResponse: {
				code: 0
			}
		};
	},
	methods: {
		saveReport: function () {
			if (this.selected.fio.length && this.selected.approve) {
				this.$parent.$emit('saveReport', true);
				this.hideIt = true;
				this.error = false;
			} else {
				this.error = true;
			}
		},
		check: function (statusCode) {
			this.saveResponse.code = statusCode;
		}
	},
	props: ['approveBar', 'hashToCustomer'],
	created() {
		if (this.approveBar) {
			this.selected = this.approveBar;
			this.pdfVisibleButton = true;
		}
		this.pdfUrl = this.$route.fullPath.replace('lawyercheck/', 'lawyercheck/pdf/');
		if (this.pdfUrl.search(/hash/) === -1) {
			this.pdfUrl = this.pdfUrl + '?hash=' + this.hashToCustomer;
		}
		this.pdfUrl += '&pdf=true';
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'LawyerCheck/autotestData'
		})
	},
	watch: {
		'selected': {
			handler() {
				this.$parent.$emit('changeApproveBar', this.selected);
			},
			deep: true
		}
	}
};
