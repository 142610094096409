import {mapGetters} from 'vuex';

export default {
	name: 'InfoAuto',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				priceUAHstring: 0,
				priceUSDstring: 0
			}
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'LawyerCheck/autotestData'
		})
	},
	created() {
		this.selected.priceUAHstring = this.autotestData.autoData.priceUAH.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
		this.selected.priceUSDstring = this.autotestData.autoData.priceUSD.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	}
};
