import TitleBar from '../LawyerCheck/TitleBar/index.vue';
import InfoAuto from '../LawyerCheck/InfoAuto/index.vue';
import InfoMreo from '../LawyerCheck/InfoMreo/index.vue';
import PhotoReport from '../LawyerCheck/PhotoReport/index.vue';
import ApproveBar from '../LawyerCheck/ApproveBar/index.vue';
import IsShowBar from '../LawyerCheck/IsShowBar/index.vue';
import PdfVersionBar from '../LawyerCheck/PdfVersionBar/index.vue';
import getBase64fromFile from '../../helpers/getBase64fromFile';
import rotateImageBase64 from '../../helpers/rotateImageBase64';
import dataURLtoFile from '../../helpers/dataURLtoFile';

import {mapGetters} from 'vuex';

export default {
	data: function () {
		return {
			mainObject: {
				orderId: '',
				customerId: ''
			},
			isShow: false,
			preloader: false
		};
	},
	init: require(`./_init/${_TARGET_}`),
	components: {
		'title-bar': TitleBar,
		'info-auto': InfoAuto,
		'info-mreo': InfoMreo,
		'photo-report': PhotoReport,
		'approve-bar': ApproveBar,
		'is-show-bar': IsShowBar,
		'pdf-version-bar': PdfVersionBar
	},
	metaInfo() {
		return {
			title: `AUTO.RIA™ — Перевірені авто на AUTO.RIA ${this.$t('Результаты юридической проверки')}`,
			meta: [
				{name: 'description', content: ''},
				{name: 'keywords', content: ''},
				{property: 'og:title', content: ''},
				{property: 'og:description', content: ''},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/'},
				{
					rel: 'stylesheet',
					media: 'screen',
					type: 'text/css',
					href: 'https://css.riastatic.com/stylesheets/checklist_sto.css?v=1.01'
				},
				{
					rel: 'stylesheet',
					media: 'print',
					type: 'text/css',
					href: 'https://css.riastatic.com/stylesheets/checklist_sto-print.css?v=1.01'
				},
			]
		};
	},
	name: 'LawyerCheck',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'LawyerCheck/autotestData',
			userData: 'Common/userData',
			reportData: 'LawyerCheck/reportData'
		})
	},
	created() {
		if (Object.keys(this.reportData).length && (this.autotestData.isSTO || this.autotestData.isCustomer)) {
			this.mainObject = this.reportData.mainObject;
		}

		if (this.autotestData.accessDenied) {
			this.mainObject.orderId = this.autotestData.orderData.orderId;
			this.mainObject.customerId = this.autotestData.orderData.customerId;
		}

		function saveChangedReport(_this) {
			if (_this.autotestData.isSTO) {
				// сохранение объекта формы редирект на страницу благодарности
				_this.$http.post('/report/diagnostic/', {mainObject: _this.mainObject})
					.then((response) => {
						_this.$refs.approve.check(response.status);
						_this.preloader = false;
					})
					.catch((response) => {
						_this.$refs.approve.check(response.status);
						_this.preloader = false;
					});
			}
		}

		function promiseSaveAllPhotoInReport(_this) {
			return Promise.all(_this.mainObject.photoReport.map(async (photo, index) => {
				return new Promise(async (resolve, reject) => {
					if (photo.originFile) {
						let {angle, originFile} = photo;
						let base64 = await getBase64fromFile(originFile);
						if (angle === undefined) {
							angle = 0;
						}
						let rotateImage = await rotateImageBase64(base64, angle);
						let file = dataURLtoFile(rotateImage, photo.originFile.name);
						let formData = new FormData;

						formData.append('file', file);
						_this.$http.post('/plupload', formData)
							.then((response) => {
								if (!Object.keys(response).length) {
									throw new Error('error uploading phot @vue:#17724120DC3');
								}
								response.report_id = _this.reportId;
								let request_object = response.body;
								request_object.report_id = _this.mainObject.orderId;
								_this.$http.post(`/autotest/uploadAutotestPhoto?name=${request_object.name}&hash=${request_object.hash}&report_id=${request_object.report_id}`)
									.then((data) => {
										if (data.body.error) {
											_this.preloader = false;
											_this.$refs.approve.check(503);
										} else {
											_this.mainObject.photoReport[index] = data.body;
											_this.mainObject.photoReport[index].report_id = _this.mainObject.orderId;
											_this.mainObject.photoReport[index].photo_id = Number(data.body.path.match(/\/(\d+).jpg/)[1]);
											_this.mainObject.photoReport[index].path = _this.mainObject.photoReport[index].path.replace('.', 'f.');
											resolve();
										}
									});
							})
							.catch(console.error);

					} else {
						let {angle, photo_id, originAngle, path} = photo;
						if (originAngle) {
							_this.mainObject.photoReport[index].angle = angle + originAngle >= 360 ? angle + originAngle % 360 : angle + originAngle;
						}
						if (angle && path) {
							_this.mainObject.photoReport[index].rotate = true;
						}
						resolve();
					}
				});
			}));
		}

		this.$on('saveReport', async function (isSave) {
			this.preloader = true;
			if (this.mainObject.photoReport.length) {
				promiseSaveAllPhotoInReport(this).then(() => {
					saveChangedReport(this);
				});
			} else {
				saveChangedReport(this);
			}
		});

		this.$on('changeApproveBar', function (approveBar) {
			this.mainObject.approveBar = approveBar;
		});

		this.$on('changePhotoReport', function (photoReport) {
			this.mainObject.photoReport = photoReport;
		});
	}

};
