const MAX_SIZE = 5242880 * 2; // 10Mb

import {mapGetters} from 'vuex';
import PhotoBox from '../PhotoReport/PhotoBox/index.vue';
import AddPhotoButton from '../../Common/PhotoSection/AddPhotoButton/index.vue';

export default {
	components: {
		PhotoBox,
		AddPhotoButton
	},
	name: 'PhotoReport',
	i18n: require('./i18n').default,
	data: function () {
		return {
			files: []
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'LawyerCheck/autotestData',
			reportData: 'LawyerCheck/reportData'
		})
	},
	created() {
		if (this.reportData.mainObject) {
			this.files = this.reportData.mainObject.photoReport;
			this.files.map((item, index) => {
				if (item.angle) {
					this.files[index].originAngle = item.angle;
					delete this.files[index].angle;
				}
			});
		}
	},
	methods: {
		openPhotoPicker() {
			this.$refs.photos.value = '';
			this.$refs.photos.click();
		},
		async onPhotosListChange(event) {
			let {target: {files = []} = {}} = event;

			let filesList = Array
				.from(files)
				.filter((item) => {
					let isSmaller = item.size < MAX_SIZE;
					return isSmaller;
				})
				.map((item = {}) => {
					return {originFile: item};
				});

			this.files.push(...filesList);

		},
		deleteItem(index, photoId) {

			this.$http.delete('/autotest/photos/' + photoId);

			if (Number.isInteger(index)) {
				this.files.splice(index, 1);
			}

			this.files.map((file, index) => {
				let {angle} = file;
				return angle;
			});
		}
	},
	watch: {
		'files': {
			handler() {
				this.$parent.$emit('changePhotoReport', this.files);
			},
			deep: true
		}
	}
};
