export default {
	messages: {
		ru: {
			'Проверено': 'Проверено',
			'Вы можете прикрепить результаты проверки к вашему объявления': 'Вы можете прикрепить результаты проверки к вашему объявления и получить специальную плашку',
			'которая поможет получить больше доверия покупателей.': 'которая поможет получить больше доверия покупателей.',
			'Добавить результаты в объявление': 'Добавить результаты в объявление',
			'Скачать': 'Скачать',
			'При нажатии на кнопку «Добавить результаты в объявление», вы соглашаетесь с условиями': 'При нажатии на кнопку «Добавить результаты в объявление», вы соглашаетесь с условиями',
			'публичного договора': 'публичного договора',
			'Результаты юридической проверки добавлены в объявление!': 'Результаты юридической проверки добавлены в объявление!'
		},
		uk: {
			'Проверено': 'Перевірено',
			'Вы можете прикрепить результаты проверки к вашему объявления': 'Ви можете прикріпити результати перевірки до вашого оголошення та отримати спеціальну плашку',
			'которая поможет получить больше доверия покупателей.': 'яка допоможе отримати більше довіри покупців.',
			'Добавить результаты в объявление': 'Додати результати в оголошення',
			'Скачать': 'Завантажити',
			'При нажатии на кнопку «Добавить результаты в объявление», вы соглашаетесь с условиями': 'При натистені на кнопку «Додати результати у оголошенння», ви погоджуєтесь з умовами',
			'публичного договора': 'публічного договору',
			'Результаты юридической проверки добавлены в объявление!': 'Результати юридичної перевірки додані в оголошення!'
		}
	}
};
