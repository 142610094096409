import {mapGetters} from 'vuex';
import rotateImageBase64 from '../../../../helpers/rotateImageBase64';
import getBase64fromFile from '../../../../helpers/getBase64fromFile';
import getBase64fromURL from '../../../../helpers/getBase64fromURL';

export default {
	data: function () {
		return {
			open: false
		};
	},
	props: ['files', 'index', 'item', 'reportId'],
	computed: {
		...mapGetters({
			autotestData: 'LawyerCheck/autotestData',
		})
	},
	async mounted() {
		this.setPreview();
	},
	methods: {
		async setPreview() {
			// перерисовка
			let {originFile, angle} = this.item,
				base64, data,
				_this = this;

			if (this.item.path) {
				await getBase64fromURL('https://cdn.riastatic.com/photos/' + this.item.path, 'image/jpeg').then(async (response) => {
					angle = angle ? angle : 0;
					await rotateImageBase64(response, angle).then((photoData) => {
						angle === 0 ? _this.$refs.preview.src = response : _this.$refs.preview.src = photoData;
					});
				});
				await getBase64fromURL('https://cdn.riastatic.com/photos/' + this.item.path.replace('f.', 'xfx.'), 'image/jpeg').then(async (response) => {
					angle = angle ? angle : 0;
					await rotateImageBase64(response, angle).then((photoData) => {
						angle === 0 ? _this.$refs.previewPopup.src = response : _this.$refs.previewPopup.src = photoData;
					});
				});
			} else {
				base64 = await getBase64fromFile(originFile).then(async (response) => {
					angle = angle ? angle : 0;
					data = await rotateImageBase64(response, angle).then((photoData) => {
						angle === 0 ? _this.$refs.preview.src = response : _this.$refs.preview.src = photoData;
						angle === 0 ? _this.$refs.previewPopup.src = response : _this.$refs.previewPopup.src = photoData;
					});
				});
			}
		},
		async rotate() {
			// поворот
			let {originFile, angle} = this.item,
					base64, data,
					_this = this;
			if (this.item.path) {
				angle = angle ? angle : 0;
				angle = angle >= 360 ? angle % 360 : angle;
				angle += 90;
				// фото коротое уже было в отчете
				await getBase64fromURL('https://cdn.riastatic.com/photos/' + this.item.path, 'image/jpeg').then(async (response) => {
					await rotateImageBase64(response, angle).then((photoData) => {
						angle === 0 ? _this.$refs.preview.src = response : _this.$refs.preview.src = photoData;
					});
					this.item.angle = angle;
				});

				// новое фото, только что загруженное
				await getBase64fromURL('https://cdn.riastatic.com/photos/' + this.item.path.replace('f.', 'xfx.'), 'image/jpeg').then(async (response) => {
					await rotateImageBase64(response, angle).then((photoData) => {
						angle === 0 ? _this.$refs.previewPopup.src = response : _this.$refs.previewPopup.src = photoData;
					});
					this.item.angle = angle;
				});

			} else {
				base64 = await getBase64fromFile(originFile).then(async (response) => {
					angle = angle ? angle : 0;
					angle = angle >= 360 ? angle % 360 : angle;
					angle += 90;
					data = await rotateImageBase64(response, angle).then((photoData) => {
						angle === 0 ? _this.$refs.preview.src = response : _this.$refs.preview.src = photoData;
						angle === 0 ? _this.$refs.previewPopup.src = response : _this.$refs.previewPopup.src = photoData;
					});

					this.item.angle = angle;
				});
			}

		},
		remove() {
			this.$emit('delete', this.index, this.item.report_photo_id);
		},
		zoom() {
			this.open = true;
		},
		closeZoom() {
			this.open = false;
		}
	},
	watch: {
		files: function () {
			this.setPreview();
		},
		'open': {
			handler() {
				this.open ?
					document.getElementsByTagName('body')[0].setAttribute('class', 'modal-open page-overflow') :
					document.getElementsByTagName('body')[0].setAttribute('class', '');
			},
			deep: true
		}
	}
};
